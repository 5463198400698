
#main-view {
  .content {
    flex: 1 1 auto;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    position: relative;
  }
}
