
#navigation {
    #logo-holder {
        height: 64px;
        padding-top: 4px;
        padding-bottom: 4px;

        #logo {
            // margin-left: 35px;
            margin: 0 auto;
            min-height: 32px;
            transition: margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        }
    }

    .v-list.v-list-navigation {
        padding: 0;

        .v-list-item {
            padding: -10px 10px;

            .v-list-item__icon {
                margin-right: 20px;
                .v-icon {
                    svg {
                        width: 30px;
                        height: 30px;
                    }

                    &.v-icon-fill {
                        svg {
                            path {
                                fill: #848c9c;
                            }

                            rect {
                                fill: #848c9c;
                            }

                            circle {
                                fill: #848c9c;
                            }
                        }
                    }

                    &.v-icon-stroke {
                        svg {
                            path {
                                stroke: #848c9c;
                            }

                            rect {
                                stroke: #848c9c;
                            }

                            circle {
                                stroke: #848c9c;
                            }
                        }
                    }
                }
            }

            .v-list-item__title {
                color: #848c9c;
            }
        }

        .router-link {
            text-decoration: none;

            &.router-link-active {
                .btn-icon {
                    background-color: var(--v-primaryTextColor-base);
                }
                .v-list-item {
                    // border-left: 5px solid var(--v-primary-base);
                    // background-color: var(--v-secondaryBackgroundColor-base);

                    &.theme--light {
                        // border-left: 5px solid var(--v-primary-base);
                        // color: var(--v-secondaryBackgroundColor-base);

                        .v-list-item__title {
                            color: var(--v-primaryTextColor-base);
                        }
                    }

                    .v-list-item__title {
                        color: rgba(0, 0, 0, 0.87);
                    }

                    .v-list-item__icon {
                        margin-left: 10px;

                        .v-icon-fill {
                            svg {
                                path {
                                    fill: white;
                                }

                                rect {
                                    fill: var(--v-primary-base);
                                }

                                circle {
                                    fill: var(--v-primary-base);
                                }
                            }
                        }

                        .v-icon-stroke {
                            svg {
                                path {
                                    stroke: var(--v-primary-base);
                                }

                                rect {
                                    stroke: var(--v-primary-base);
                                }

                                circle {
                                    stroke: var(--v-primary-base);
                                }
                            }
                        }
                    }
                }
            }
            .v-list-item__icon {
                margin-left: 10px;
            }
        }
    }

    // .v-navigation-drawer--mini-variant {
    //   #logo-holder #logo {
    //     margin-left: 0;
    //   }
    // }

    .v-navigation-drawer {
        &__prepend {
            #logo-holder {
                // border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
            }
        }
    }

    &.v-navigation-drawer--is-mobile {
        .v-navigation-drawer__content {
            overflow-y: auto;
        }
    }
}
